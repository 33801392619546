import { useState } from 'react'
import { coffeeShops } from '../../data/data'
import { YMaps } from '@pbe/react-yandex-maps'
import CoffeeShopsMap from '../../components/CoffeeShopsMap'
import useTitle from "../../hooks/useTitle"
import useDescription from '../../hooks/useDescription'
import useCanonical from "../../hooks/useCanonical"

const CoffeeShopsView = () => {
  useTitle('Кофейни')
  useDescription('Наши кофейни: названия, адреса, режим работы.')
  useCanonical(import.meta.env.VITE_FRONTEND_URL + 'coffee-shops')

  const [activeTab, setActiveTab] = useState(null)

  return (
    <div className="container">
      <div className="tabs-container">
        <div className="tabs tabs-profile">
          <div className="heading-h1 heading-h1-coffee-shops">Кофейни</div>
          <div className={`tab-link ${activeTab == 'rockets_concept_store' ? 'active' : ''}`} onClick={() => setActiveTab('rockets_concept_store')}>Rockets Concept Store</div>
          <div className={`tab-link ${activeTab == null ? 'active' : ''}`} onClick={() => setActiveTab(null)}>Все кофейни</div>
          <div className={`tab-link ${activeTab == 'coffee_with_food' ? 'active' : ''}`} onClick={() => setActiveTab('coffee_with_food')}>Кофе с едой</div>
          <div className={`tab-link ${activeTab == 'cappuccino_kids' ? 'active' : ''}`} onClick={() => setActiveTab('cappuccino_kids')}>Cappuccino Kids</div>
        </div>
        <div className="tabs-content">
          <div className="mb-20" />
          <div className="coffee-shops-map-container">
            <YMaps>
              <CoffeeShopsMap coffeeShops={coffeeShops} activeTab={activeTab} />
            </YMaps>
          </div>
          <div className="coffee-shops-list">
            {coffeeShops
              .filter(shop => activeTab ? shop.tags.includes(activeTab) : true)
              .map((shop, index) => (
                <div key={index} className="coffee-shop-item">
                  <div className="coffee-shop-item__name">{shop.name}</div>
                  <div className="coffee-shop-item__description">{shop.description.map((elem, index) => <p key={index}>{elem}</p>)}</div>
                  <div className="coffee-shop-item__image"><img src={shop.image} alt="" /></div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CoffeeShopsView