import { useEffect, memo } from 'react'
import Modal from '../../Modal'
import { useGetPickupPointsBoxberryQuery } from '../../../features/backend/backendApiSlice'
import { YMaps, Map } from "@pbe/react-yandex-maps"
import MapClusterer from './Map/MapClusterer'

const CartFormModalPickupBoxberry = ({ isOpen, onClose, onSelect, query, validateField, setDeliveryPriceBoxberry }) => {
  const skip = (!query?.city) ? true : false
  const { data = {}, isLoading, isFetching } = useGetPickupPointsBoxberryQuery(
    query,
    { skip },
  )

  useEffect(() => {
    console.log(data.points)
    if (data.points?.length && data.points[0]?.Code) {
      console.log(data?.points[0]?.Code)
      setDeliveryPriceBoxberry(data.points[0].Code)
    } else if (data?.error) {
      setDeliveryPriceBoxberry(false)
    }
  }, [data])

  return (
    <Modal heading="Пункты самовывоза" isOpen={isOpen} onClose={onClose}>
      {(isLoading || isFetching) ? <p>Загрузка...</p> :
       (!data?.points?.length || !data.points[0]?.GPS) ? <p>Пункты самовывоза Боксберри отсутствуют в вашем городе</p> : (
        <div className="yandex-map-wrapper">
          <YMaps>
            <Map
              state={{
                center: [
                  data.points[0].GPS.split(',')[0],
                  data.points[0].GPS.split(',')[1],
                ],
                zoom: 10,
              }}
              width="100%"
              height="500px"
            >
              <MapClusterer
                placemarks={data.points.map((point) => ({
                  location: {
                    latitude: point.GPS.split(',')[0],
                    longitude: point.GPS.split(',')[1],
                  },
                  name: point.Name,
                  address: point.Address,
                  destinationStationId: point.Code,
                }))}
                onSelect={(placemark) => {
                  onSelect(placemark.address, placemark.destinationStationId)
                  validateField(placemark.address)
                  onClose()
                }}
              />
            </Map>
          </YMaps>
        </div>
      )}
    </Modal>
  )
}

export default memo(CartFormModalPickupBoxberry)