import { loyaltyStages } from '../../data/data'
import useTitle from "../../hooks/useTitle"
import useDescription from '../../hooks/useDescription'
import useCanonical from "../../hooks/useCanonical"

const LoyaltySystemView = () => {
  useTitle('Бонусная система')
  useDescription('Правила и условия бонусной системы в интернет магазине - rockets.coffee.')
  useCanonical(import.meta.env.VITE_FRONTEND_URL + 'loyalty-system')
  
  return (
    <div className="container">
      <div className="heading-h1">Бонусная система</div>
      <div className="loyalty-system-container">
        <div className="loyalty-system-pellets">
          <div className="profile-bonuses-stages-other">
            {loyaltyStages.map((stage, index) => (
              <div key={index} className="profile-bonuses-stage">
                <div className="profile-bonuses-stage__heading">{stage.name}</div>
                <div className="profile-bonuses-stage__value">{stage.discount}%</div>
                <div className="profile-bonuses-stage__note">cashback в интернет-магазине {stage.threshold == 0 ? `сразу после регистрации` : `при сумме заказов от ${stage.threshold} руб.`}</div>
              </div>
            ))}
            <div className="profile-bonuses-stage profile-bonuses-stage--special">
              <div className="profile-bonuses-stage__heading">для всех уровней</div>
              <div className="profile-bonuses-stage__value">3%</div>
              <div className="profile-bonuses-stage__note">cashback в наших кофейнях</div>
            </div>
          </div>
        </div>
        <div>
          <div className="heading-h2 loyalty-system-heading">После регистрации на сайте вы становитесь участником бонусной системы <span className="branded-text">rockets.coffee</span></div>
          <p className="loyalty-system-text">Накопить и потратить баллы кэшбэка можно в интернет-магазине rockets.coffee и в наших кофейнях. Размер кэшбэка зависит от общей суммы оформленных заказов на сайте и в кофейнях. Для получения или использования бонусов необходимо авторизоваться в личном кабинете, оформить заказ или предьявить карту лояльности в кофейне. Бонусные баллы будут начислены после успешного выполнения заказа. Ваш текущий статус и баланс отображается в личном кабинете в разделе «бонусная система». Баллами можно оплатить до 100% от стоимости заказа. Если остались вопросы, звоните!</p>
          <p><strong>8 800 777-04-14</strong></p>
        </div>
      </div>
    </div>
  )
}

export default LoyaltySystemView