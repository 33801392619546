import { useState } from 'react'
import { dictionary } from '../../data/data'
import useTitle from "../../hooks/useTitle"
import useDescription from '../../hooks/useDescription'
import useCanonical from "../../hooks/useCanonical"

const CoffeeDictionaryView = () => {
  useTitle('Кофейный словарь')
  useDescription('Глоссарий rockets.coffee.')
  useCanonical(import.meta.env.VITE_FRONTEND_URL + 'coffee-dictionary')

  const [activeTab, setActiveTab] = useState(dictionary[0].term)

  const currentTerm = dictionary.find((item) => item.term == activeTab)

  return (
    <>
      <div className="container">
        <div className="heading-h1">Кофейный словарь</div>
        <div className="tabs-container tabs-container-coffee-dictionary">
          <div className="tabs">
            <p className="tabs-info">Мы собрали основные термины, которые употребляются в составе кофе, его производстве и приготовлении.</p>
            {dictionary.map((item, index) => (
              <div key={index} className={`tab-link ${activeTab == item.term ? 'active' : ''}`} onClick={() => setActiveTab(item.term)}>{item.term}</div>
            ))}
          </div>
          <div className="tabs-content">
            <div className="coffee-dictionary-content">
              <div className="heading-h2-branded-bold">{currentTerm.term}</div>
              <p>{currentTerm.description}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CoffeeDictionaryView