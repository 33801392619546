import { useState, useMemo, memo } from 'react'
import Modal from '../../components/Modal'
import useForm from '../../hooks/useForm'
import { sendContactsForm } from '../../utils/api'
import { Link } from 'react-router-dom'
import FormFieldText from '../../components/Form/FormFieldText'
import FormFieldTextarea from '../../components/Form/FormFieldTextarea'
import FormFieldCheckbox from '../../components/Form/FormFieldCheckbox'
import FormFieldMasked from '../../components/Form/FormFieldMasked'

const ContactsModalForm = ({ isOpen, onClose }) => {
  const [isFormSent, setIsFormSent] = useState(false)

  const phoneMask = '+_ ___ ___ __ __'

  const {
    fields,
    invalidFields,
    validateFieldMemoized,
    setFieldMemoized,
    setFieldMultiple,
    handleSubmit,
    isSubmitting,
    error,
  } = useForm({
    phone: phoneMask,
    acceptTerms: true,
  }, {
    name: (value) => value?.length < 1,
    phone: (value) => value?.length < 1 || value.indexOf('_') != -1,
    email: (value) => value?.length < 1 || !value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i),
    acceptTerms: (value) => !value,
  }, async (fields, setError) => {
    let utms = localStorage.getItem('utms')
    if (utms == null) {
      utms = []
    } else {
      utms = JSON.parse(utms)
    }

    const response = await sendContactsForm({ name: fields.name, phone: fields.phone, email: fields.email, comments: fields.comments, utms: utms })

    if (!response.success) {
      setError('Ошибка отправки формы')
      return
    }

    setIsFormSent(true)
  })

  return (
    <Modal heading="Остались вопросы?" isOpen={isOpen} onClose={onClose} className="narrow">
      <form onSubmit={handleSubmit} className={isFormSent ? 'display-none' : ''}>
        {error && <div className="form-error">{error}</div>}
        <div className="form-fields-container-1col">
          <FormFieldText
            value={fields?.name ?? ''}
            isInvalid={invalidFields.name}
            placeholder={'Имя'}
            onChange={setFieldMemoized('name')}
            validateField={validateFieldMemoized('name')}
          />
          {/* <FormFieldText
            value={fields?.phone ?? ''}
            isInvalid={invalidFields.phone}
            placeholder={'Телефон'}
            onChange={setFieldMemoized('phone')}
            validateField={validateFieldMemoized('phone')}
          /> */}
          <FormFieldMasked
            mask={phoneMask}
            value={fields?.phone ?? ''}
            isInvalid={invalidFields.phone}
            placeholder={'Телефон'}
            onChange={setFieldMemoized('phone')}
            validateField={validateFieldMemoized('phone')}
          />
          <FormFieldText
            value={fields?.email ?? ''}
            isInvalid={invalidFields.email}
            placeholder={'Эл. почта'}
            onChange={setFieldMemoized('email')}
            validateField={validateFieldMemoized('email')}
          />
          <FormFieldTextarea
            value={fields?.comments ?? ''}
            isInvalid={invalidFields.comments}
            placeholder={'Комментарий'}
            onChange={setFieldMemoized('comments')}
            validateField={validateFieldMemoized('comments')}
          />
          <FormFieldCheckbox
            label={useMemo(() => <div className="contacts-modal-form-user-agreement">Я принимаю условия <Link to="/user-agreement">Политики конфиденциальности</Link></div>, [])}
            active={fields?.acceptTerms ?? false}
            onChange={setFieldMemoized('acceptTerms')}
            isValid={!invalidFields?.acceptTerms}
          />
          <button type="submit" className={`button-big-primary-wide ${(isSubmitting || (!fields?.name || fields?.phone?.indexOf('_') != -1 || !fields?.email)) ? 'disabled' : ''}`}>
            {isSubmitting ? 'Отправляем...' : 'Отправить'}
          </button>
        </div>
      </form>
      {isFormSent && <div className="form-success">Спасибо, ваш запрос отправлен!</div>}
    </Modal>
  )
}

export default memo(ContactsModalForm)