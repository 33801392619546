import { useState } from 'react'
import SubTab from '../../components/SubTab'
import useTitle from "../../hooks/useTitle"
import useDescription from '../../hooks/useDescription'
import useCanonical from "../../hooks/useCanonical"

const FaqView = () => {
  useTitle('FAQ - вопросы и ответы')
  useDescription('Ответы на самые популярные вопросы о кофе, заказах и rockets.coffee.')
  useCanonical(import.meta.env.VITE_FRONTEND_URL + 'faq')

  const [activeTab, setActiveTab] = useState(1)
  const [activeSubtab, setActiveSubtab] = useState(null)

  return (
    <div className="container">
      <div className="heading-h1">FAQ</div>
      <div className="tabs-container">
        <div className="tabs">
          <div className={`tab-link ${activeTab == 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}>Выбор кофе</div>
          <div className={`tab-link ${activeTab == 2 ? 'active' : ''}`} onClick={() => setActiveTab(2)}>Заказ</div>
          <div className={`tab-link ${activeTab == 3 ? 'active' : ''}`} onClick={() => setActiveTab(3)}>О Rockets</div>
        </div>
        <div className="tabs-content">
          <div className={`subtabs-container ${activeTab != 1 ? 'display-none' : ''}`}>
            <SubTab active={activeSubtab == 1} toggle={() => setActiveSubtab(activeSubtab == 1 ? null : 1)} heading="Как выбрать кофе?">
              <p>Используйте фильтр в каталоге. Выберите предпочитаемый тип обжарки, уровень кислотности, способ заваривания или обработки. Для определения подходящего кофе по вкусовым параметрам — обращайте внимание на шкалу вкусов — кислотности, сладости и горечи.</p>
            </SubTab>
            <SubTab active={activeSubtab == 2} toggle={() => setActiveSubtab(activeSubtab == 2 ? null : 2)} heading="Как заварить кофе в домашних условиях?">
              <p>В разделе знания мы рассказываем про популярные методы заваривания кофе в домашних условиях. Остается выбрать тот, который подойдет именно вам.</p>
            </SubTab>
            <SubTab active={activeSubtab == 3} toggle={() => setActiveSubtab(activeSubtab == 3 ? null : 3)} heading="Как рекомендуете хранить кофе?">
              <p>Рекомендуем хранить кофе в закрытой упаковке, в сухом прохладном месте, беречь от прямых солнечных лучей и влаги.</p>
            </SubTab>
            <SubTab active={activeSubtab == 4} toggle={() => setActiveSubtab(activeSubtab == 4 ? null : 4)} heading="Сколько хранится ваш кофе?">
              <p>Срок хранения кофе составляет 12 месяцев с даты изготовления.</p>
            </SubTab>
          </div>
          <div className={`subtabs-container ${activeTab != 2 ? 'display-none' : ''}`}>
            <SubTab active={activeSubtab == 5} toggle={() => setActiveSubtab(activeSubtab == 5 ? null : 5)} heading="Как быстро вы доставите заказ?">
              <p>При оформлении заказа до 14:00, мы доставим его на следующий рабочий день по Москве, МО, Санкт-Петербургу, ЛО и городам ЦФО.</p>
            </SubTab>
            <SubTab active={activeSubtab == 6} toggle={() => setActiveSubtab(activeSubtab == 6 ? null : 6)} heading="Какие есть способы доставки?">
              <p>Курьерская доставка по России, ПВЗ СДЭК по России и СНГ, самовывоз из кофеен в Москве.</p>
            </SubTab>
            <SubTab active={activeSubtab == 7} toggle={() => setActiveSubtab(activeSubtab == 7 ? null : 7)} heading="Как отследить заказ?">
              <p>Статус заказа отображается в личном кабинете на сайте, если вы зарегистрированный пользователь. Также вы можете позвонить по телефону 8 800 777-04-14 или написать на почту shop@rockets.coffee и получить информацию по заказу.</p>
            </SubTab>
            <SubTab active={activeSubtab == 8} toggle={() => setActiveSubtab(activeSubtab == 8 ? null : 8)} heading="Как воспользоваться промокодом?">
              <p>Перед оформлением заказа необходимо зарегистрироваться. Далее в корзине в поле для промокода необходимо ввести значение и нажать на стрелку.</p>
            </SubTab>
          </div>
          <div className={`subtabs-container ${activeTab != 3 ? 'display-none' : ''}`}>
            <SubTab active={activeSubtab == 9} toggle={() => setActiveSubtab(activeSubtab == 9 ? null : 9)} heading="Если у вас бонусы и скидки?">
              <p>Для наших постоянных клиентов предусмотрена бонусная система. Размер начисляемого кешбэка зависит от общей суммы оформленных заказов. Текущее количество бонусов отображается в личном кабинете.</p>
            </SubTab>
            <SubTab active={activeSubtab == 10} toggle={() => setActiveSubtab(activeSubtab == 10 ? null : 10)} heading="Как давно вы обжариваете кофе?">
              <p>rockets.coffee обжаривает зерно c 2014 года.</p>
            </SubTab>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FaqView