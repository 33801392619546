import { catalogFiltersToggled, selectCatalogFilters } from '../../features/catalogFilters/catalogFiltersSlice'
import { useDispatch, useSelector } from 'react-redux'

const CatalogFiltersPellet = ({ category, option }) => {
  const dispatch = useDispatch()
  
  const handleClick = () => {
    dispatch(catalogFiltersToggled({ category, optionId: option.id }))

    const url = new URL(window.location)
    const params = new URLSearchParams(url.search)
    
    if (params.has('filters')) {
      let current = params.get('filters').split(',')
      if (current.includes(`${category}:${option.id}`)) {
        current = current.filter(id => id !== `${category}:${option.id}`)
        params.set('filters', current.join(','))
      } else {
        params.set('filters', [...params.getAll('filters'), `${category}:${option.id}`].join(','))
      }
    } else {
      params.set('filters', `${category}:${option.id}`)
    }
    url.search = params.toString()
    window.history.pushState({}, '', url)
  }

  const filters = useSelector(selectCatalogFilters)
  const categoryFilters = filters.filter(filter => filter.category === category)
  const active = categoryFilters.find(filter => filter.optionId === option.id)

  return (
    <div
      className={`catalog-filters-pellet ${active ? 'active' : ''}`}
      onClick={handleClick}
    >
      <div>{option.name}</div>
      <div className="catalog-filters-pellet__close">
        <img src="/images/icons/close-white.svg" alt="" />
      </div>
    </div>
  )
}

export default CatalogFiltersPellet