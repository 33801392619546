import { useState } from 'react'
import { useGetProductsQuery } from "../features/backend/backendApiSlice"
import { SLUG_CAT_WEEKS_CHOICE, SLUG_CAT_NEW } from '../utils/enums'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/css'
import Product from '../components/Product'

const FeaturedProducts = ({showNew = false}) => {
  const [activeTab, setActiveTab] = useState(showNew ? SLUG_CAT_NEW : SLUG_CAT_WEEKS_CHOICE)

  const { data: productsAll } = useGetProductsQuery()

  const productsWeeksChoice = productsAll ? productsAll.filter(product => product.categories.find(category => category.slug == SLUG_CAT_WEEKS_CHOICE)) : []
  const productsNew = productsAll ? productsAll.filter(product => product.categories.find(category => category.slug == SLUG_CAT_NEW)) : []

  return (
    <div className="featured-products">
      <div className="featured-products-tabs">
        <div
          className={`featured-products-tab ${activeTab == SLUG_CAT_WEEKS_CHOICE ? 'active' : ''}`}
          onClick={() => setActiveTab(SLUG_CAT_WEEKS_CHOICE)}
        >
          Выбор недели
        </div>
        <div
          className={`featured-products-tab ${activeTab == SLUG_CAT_NEW ? 'active' : ''}`}
          onClick={() => setActiveTab(SLUG_CAT_NEW)}
        >
          Новинки
        </div>
      </div>
      <div className={`${activeTab != SLUG_CAT_WEEKS_CHOICE ? 'display-none' : ''}`}>
        {productsWeeksChoice.length > 0 && <div className="styled-splide-container">
          <Splide options={{
            perPage: 3,
            perMove: 1,
            gap: '15px',
            pagination: true,
            arrows: false,
            breakpoints: { 991: { perPage: 2 }, 768: { perPage: 1 } }
          }}>
            {productsWeeksChoice.map((product) => (
              <SplideSlide key={product.id}>
                <Product product={product} />
              </SplideSlide>
            ))}
          </Splide>
        </div>}
      </div>
      <div className={`${activeTab != SLUG_CAT_NEW ? 'display-none' : ''}`}>
        {productsNew.length > 0 && <div className="styled-splide-container">
          <Splide options={{
            perPage: 3,
            perMove: 1,
            gap: '15px',
            pagination: true,
            arrows: false,
            breakpoints: { 991: { perPage: 2 }, 768: { perPage: 1 } }
          }}>
            {productsNew.map((product) => (
              <SplideSlide key={product.id}>
                <Product product={product} />
              </SplideSlide>
            ))}
          </Splide>
        </div>}
      </div>
    </div>
  )
}

export default FeaturedProducts