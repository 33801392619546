import { useState, memo } from 'react'
import { InputMask } from '@react-input/mask'

const FormFieldPhone = ({ phoneCode, phoneNumber, setPhoneCode, setPhoneNumber, isInvalid, validateField, setPhoneCountry = null }) => {
  const options = [
    {
      code: '+7',
      flag: 'ru',
      mask: '___ ___ __ __',
      regExp: /[0-9]{3} [0-9]{3} [0-9]{2} [0-9]{2}/,
    },
    {
      code: '+375',
      flag: 'by',
      mask: '__ ___ __ __',
      regExp: /[0-9]{2} [0-9]{3} [0-9]{2} [0-9]{2}/,
    },
    {
      code: '+7',
      flag: 'kz',
      mask: '___ ___ __ __',
      regExp: /[0-9]{3} [0-9]{3} [0-9]{2} [0-9]{2}/,
    },
    {
      code: '+374',
      flag: 'am',
      mask: '__ __ __ __',
      regExp: /[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}/,
    },
    {
      code: '+996',
      flag: 'kg',
      mask: '___ __ __ __',
      regExp: /[0-9]{3} [0-9]{2} [0-9]{2} [0-9]{2}/,
    },
    // {
    //   code: '+995',
    //   flag: 'ge',
    //   mask: '___ __ __ __',
    //   regExp: /[0-9]{3} [0-9]{2} [0-9]{2} [0-9]{2}/,
    // }
  ]

  const [dropdown, setDropdown] = useState(false)
  const [flag, setFlag] = useState(options.find(elem => elem.code == phoneCode)?.flag)
  const [mask, setMask] = useState(options.find(elem => elem.code == phoneCode)?.mask)
  const [regExp, setRegExp] = useState(options.find(elem => elem.code == phoneCode)?.regExp)

  const handleSetPhoneCode = (code, flag) => {
    const selected = options.find(elem => elem.code == code)

    setPhoneCode(code, selected.mask)
    setFlag(flag)
    setMask(selected.mask)
    setRegExp(selected.regExp)
    setDropdown(false)

    if (setPhoneCountry) {
      setPhoneCountry(flag)
    }
  }

  return (
    <div className="cart-form-field-phone-wrapper">
      <div className={`cart-form-field-phone-code ${isInvalid ? 'field-invalid' : ''}`} onClick={() => setDropdown(!dropdown)}>
        <img src={`/images/flags/${flag}.svg`} width="16" alt="" />
        <div>{phoneCode}</div>
        <img src="/images/utils/arrow-down.svg" width="9" alt="" />
        <div className={`cart-form-field-phone-code-dropdown ${dropdown ? 'active' : ''}`}>
          {options.map((option, index) => (
            <div key={index} className="cart-form-field-phone-code-option" onClick={() => handleSetPhoneCode(option.code, option.flag)}>
              <img src={`/images/flags/${option.flag}.svg`} width="16" alt="" />
              <div>{option.code}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="cart-form-field-wrapper">
        <div className="cart-form-field-label cart-form-field-label--active">
          Телефон
        </div>
        <InputMask
          mask={mask}
          replacement={{ _: /\d/ }}
          showMask={true}
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          onBlur={(e) => validateField(e.target.value)}
          className={`cart-form-field-wrapper-input ${isInvalid ? 'field-invalid' : ''}`}
        />
      </div>
    </div>
  )
}

export default memo(FormFieldPhone)