import { useGetUserQuery } from '../features/backend/backendApiSlice'
import { Link } from 'react-router-dom'
import useProduct from '../hooks/useProduct'
import ProductSpecs from './ProductSpecs'
import { isOnSale } from '../utils/functions'

const Product = ({ product }) => {
  const { data: user } = useGetUserQuery()

  const {
    images,
    variation,
    selectedOptions,
    openedDropdown,
    setOpenedDropdown,
    handleChangeOption,
    qty,
    handleQtyLower,
    handleQtyRaise,
    buttonFlashText,
    handleAddToCart,
    isFavorite,
    loadingIsFavorite,
    handleAddToFavorites,
    handleRemoveFromFavorites,
  } = useProduct(product)

  const imagesSorted = product?.variations ? [...product.variations]
    .slice().sort((a, b) => a.id - b.id)
    .map((variation) => [...variation.images].slice().sort((a, b) => a.pivot.order - b.pivot.order)).flat() : []

  const image = (variation && variation?.images && variation.images.length > 0)
    ? [...variation.images].slice().sort((a, b) => a.pivot.order - b.pivot.order)[0].path
    : imagesSorted[0]?.path

  return (
    <div className="catalog-product">
      <div className="catalog-product-top">
        <div className="catalog-product-topbar">
          <div className="catalog-product-topbar-labels">
            {product.tags.map((tag) => (
              <div key={tag.id} className="catalog-product-topbar-label" style={{backgroundColor: tag.color_badge, color: tag.color_text}}>{tag.name}</div>
            ))}
          </div>
          {user?.customer && <div className="catalog-product-topbar-favorite" onClick={() => isFavorite ? handleRemoveFromFavorites() : handleAddToFavorites()}>
            {loadingIsFavorite
              ? <img src="/images/loading.svg" alt="" />
              : (isFavorite ? <img src="/images/utils/heart-filled.svg" alt="" /> : <img src="/images/utils/heart-empty.svg" alt="" />)}
          </div>}
        </div>
        <Link to={`/products/${product.slug}`} className="catalog-product-link">
          <div className="catalog-product-image">
            {image && <img src={import.meta.env.VITE_BACKEND_URL + image} alt={product.name} title={product.name} />}
          </div>
          <div className="catalog-product-title">{product.name}</div>
          <div className="catalog-product-description mb-40" dangerouslySetInnerHTML={{__html: product.description_short}}></div>
        </Link>
      </div>
      <div className="catalog-product-bottom">
        <ProductSpecs product={product} />
        <div className="catalog-product-options">
          {selectedOptions.map((option, key) => <div key={key} className="catalog-product-option" onClick={() => setOpenedDropdown(prevState => prevState === option.attribute_id ? null : option.attribute_id)}>
            <div className="dropdown-container">
              <div className="dropdown-label">
                <div>{option.name}</div>
                <img src="/images/utils/arrow-down.svg" />
              </div>
              <div className={`dropdown-content ${openedDropdown == option.attribute_id ? 'active' : ''}`}>
                {product.attributes.find(elem => elem.attribute.id === option.attribute_id).options.map((elem, key) => (
                  <a key={key} onClick={() => handleChangeOption(option.attribute_id, elem.name)}>{elem.name}</a>
                ))}
              </div>
            </div>
          </div>)}
          <div className="catalog-product-option catalog-product-option__qty">
            <div onClick={handleQtyLower}>-</div>
            <div>{qty}</div>
            <div onClick={handleQtyRaise}>+</div>
          </div>
        </div>
        <div className={`catalog-product-buy-button ${(variation === false || product.in_stock == 0 || product.is_published == 0) ? 'disabled' : ''}`} onClick={handleAddToCart}>
          <div>
            {variation === false
              ? `Недоступно`
              : ((product.in_stock == 0 || product.is_published == 0)
                ? `Нет в наличии`
                : (buttonFlashText ? 'Добавлено' : <>Купить за <b>{isOnSale(variation) ? <><s>{variation?.price / 100} <span className="commissioner-700">₽</span></s> {variation?.price_sale / 100}</> : variation?.price / 100} <span className="commissioner-700">₽</span></b></>))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Product