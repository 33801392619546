import { useEffect } from "react"
import { useGetProductsQuery } from "../features/backend/backendApiSlice"
import { useParams } from 'react-router-dom'
import ProductPage from "../components/ProductPage"
import NotFound from "../components/NotFound"

const ProductView = () => {
  const { data, isError, isLoading, isSuccess } = useGetProductsQuery()
  const { slug } = useParams()
  
  const product = data ? data.find((product) => decodeURIComponent(product.slug) == decodeURIComponent(slug)) : null

  useEffect(() => {
    if (product) {
      window.dataLayer.push({
        "ecommerce": {
          "currencyCode": "RUB",
          "detail": {
            "products": [
              {
                "id": product.id,
                "name": product.title,
                // "price": 1345.26,
                // "brand": "Яндекс / Яndex",
                "category": product.categories.map(category => category.name).join(' / '),
                // "variant": "Оранжевый цвет",
                // "list": "Одежда",
                // "position": 1
              },
            ]
          }
        }
      })
    }
  }, [product])

  return (
    <>
      {(isLoading || isError || (isSuccess && !product)) && <div className="container">
        {isLoading && <div>Загрузка...</div>}
        {isError && <div>Произошла ошибка при загрузке</div>}
        {isSuccess && !product && <NotFound />}
      </div>}
      {isSuccess && product && <ProductPage product={product} />}
    </>
  )
}

export default ProductView