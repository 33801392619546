import { useState, useCallback } from 'react'
import { reviewCategories } from '../utils/enums'
import { useGetReviewsQuery, useGetUserQuery } from '../features/backend/backendApiSlice'
import Review from '../components/Review'
import ProfileModalReview from '../components/Profile/ProfileModalReview'
import useTitle from "../hooks/useTitle"
import useDescription from '../hooks/useDescription'
import useCanonical from "../hooks/useCanonical"

const ReviewsView = () => {
  useTitle('Отзывы')
  useDescription('Отзывы о продукции, доставке и команде rockets.coffee.')
  useCanonical(import.meta.env.VITE_FRONTEND_URL + 'reviews')

  const { data: user } = useGetUserQuery()
  const { data, isError, isLoading, isSuccess } = useGetReviewsQuery()
  const [activeTab, setActiveTab] = useState(reviewCategories[0])

  const reviews = data ? data.filter((review) => review.review_category_id == activeTab.id) : []
  const averageRating = reviews.length > 0 ? (reviews.reduce((acc, review) => acc + review.rating, 0) / reviews.length).toFixed(1) : 0

  const [modalOpen, setModalOpen] = useState(null)
  const closeModal = useCallback(() => setModalOpen(null), [])

  return (
    <>
      <ProfileModalReview
        isOpen={modalOpen == 'review'}
        onClose={closeModal}
      />
      <div className="container">
        <div className="heading-h1">Отзывы</div>
        <div className="tabs-container">
          <div className="tabs">
            {reviewCategories.map((category, index) => (
              <div key={index} className={`tab-link ${activeTab == category ? 'active' : ''}`} onClick={() => setActiveTab(category)}>{category.label}</div>
            ))}
          </div>
          <div className="tabs-content">
            {isError && <div>Произошла ошибка при загрузке отзывов</div>}
            {isLoading && <div className="loading-rocket"><img src="/images/loading-rocket.svg" alt="" /></div>}
            {isSuccess && (
              <>
                {reviews.length > 0 && <div className="reviews-heading">
                  <div className="reviews-heading__rating">
                    {[1, 2, 3, 4, 5].map((rating) => (
                      <img
                        key={rating}
                        src={`/images/utils/star-${averageRating >= rating ? 'blue' : 'gray'}.svg`}
                      />
                    ))}
                  </div>
                  <div className="reviews-heading__average">{averageRating}</div>
                </div>}
                <div className="reviews-container">
                  {reviews.length > 0
                    ? reviews.map((review) => (
                      <Review key={review.id} review={review} />
                    ))
                    : <p>Отзывов {activeTab.label.toLowerCase()} пока нет</p>}
                  {user?.customer && <div>
                    <button className="button-big-primary-wide" onClick={() => setModalOpen('review')}>Оставить отзыв</button>
                  </div>}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ReviewsView