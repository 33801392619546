// Need to use the React-specific entry point to import `createApi`
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { SLUG_KNOWLEDGE, SLUG_COUNTRIES } from "../../utils/enums"

const customBaseQuery = async (args, api, extraOptions) => {
  const baseResult = await fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BACKEND_URL + "api"
  })(args, api, extraOptions)

  // console.log(baseResult)

  if (baseResult.error?.status === 401) {
    return {}
  }

  return baseResult
}

// Define a service using a base URL and expected endpoints
export const backendApiSlice = createApi({
  baseQuery: customBaseQuery,
  reducerPath: "backendApi",
  tagTypes: ["Products", "Reviews"],
  endpoints: build => ({
    getUser: build.query({
      query: () => ({
        url: '/user',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          X_Requested_With: 'XMLHttpRequest',
        },
      }),
      keepUnusedDataFor: 300,
    }),
    getUserMcrm: build.query({
      query: () => `/user/mCRM?token=${localStorage.getItem('token_mcrm')}`,
      keepUnusedDataFor: 300,
    }),
    getProducts: build.query({
      query: () => `/products`,
      providesTags: (result = [], error, arg) => [
        "Products",
        ...result.map(({ id }) => ({ type: "Products", id }))
      ],
      keepUnusedDataFor: 180,
    }),
    getProduct: build.query({
      query: (id) => `/products/${id}`,
      providesTags: (result, error, id) => [{ type: "Products", id }],
      keepUnusedDataFor: 180,
    }),
    getReviews: build.query({
      query: () => `/reviews`,
      providesTags: [ "Reviews" ],
      keepUnusedDataFor: 180,
    }),
    getPagesKnowledge: build.query({
      query: () => `/pages?category_slug=${SLUG_KNOWLEDGE}`,
      keepUnusedDataFor: 300,
    }),
    getPagesCountries: build.query({
      query: () => `/pages?category_slug=${SLUG_COUNTRIES}`,
      keepUnusedDataFor: 300,
    }),
    getCourses: build.query({
      query: () => `/courses`,
      keepUnusedDataFor: 300,
    }),
    getFeaturedProducts: build.query({
      query: () => `/products/featured`,
      keepUnusedDataFor: 180,
    }),
    getPickupPointsCdek: build.query({
      query: ({countryIsoCode, city, cityPostalCode}) => `/utils/getPickupPointsCdek?country_iso_code=${countryIsoCode}&city=${city}&city_postal_code=${cityPostalCode ?? ''}`,
      keepUnusedDataFor: 300,
    }),
    getPickupPointsYandex: build.query({
      query: ({city}) => `/utils/getPickupPointsYandex?city=${city}`,
      keepUnusedDataFor: 300,
    }),
    getPickupPointsBoxberry: build.query({
      query: ({city, cityKladrId}) => `/utils/getPickupPointsBoxberry?city=${city}&city_kladr_id=${cityKladrId}`,
      keepUnusedDataFor: 300,
    }),
    getSettingsBySlugs: build.query({
      // query: (slugs) => `/settings/getBySlugs?slugs=${slugs.join(',')}`,
      // pass slugs as array
      query: (slugs) => {
        let endpoint = '/settings/getBySlugs'
        slugs.forEach((slug, index) => {
          endpoint += `${index === 0 ? '?' : '&'}slugs[]=${slug}`
        })
        return endpoint
      },
      keepUnusedDataFor: 300,
    }),
    getCertificatePurchased: build.query({
      query: ({code}) => `/certificates/purchased?code=${code}`,
      keepUnusedDataFor: 300,
    }),
  }),
})

export const {
  useGetUserQuery,
  useGetUserMcrmQuery,
  useGetProductsQuery,
  useGetProductQuery,
  useGetReviewsQuery,
  useGetPagesKnowledgeQuery,
  useGetPagesCountriesQuery,
  useGetCoursesQuery,
  useGetFeaturedProductsQuery,
  useGetPickupPointsCdekQuery,
  useGetPickupPointsYandexQuery,
  useGetPickupPointsBoxberryQuery,
  useGetSettingsBySlugsQuery,
  useGetCertificatePurchasedQuery,
} = backendApiSlice
